import { createApp, reactive } from 'vue';
import apiClient from '@/apiClient';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';

const app = createApp(App)
  .use(router)
  .use(i18n);

const globals = app.config.globalProperties;
globals.apiClient = apiClient;
globals.settings = {
  ledgers: reactive([]),
  taxes: reactive([]),
};

router.isReady().then(async () => {
  app.mount('#app');
});
