import EventBus from '@/eventBus';
import { createI18n } from 'vue-i18n';
import messagesNL from '@/../lang/nl.json';
import messagesEN from '@/../lang/en.json';

export const messages = {
  nl: messagesNL,
  en: messagesEN,
};

export const defaultLanguage = 'nl';
const preferred = navigator.language.split('-')[0];
const locale = messages[preferred] !== undefined ? preferred : defaultLanguage;

const i18n = createI18n({
  locale,
  messages,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
});

export default i18n;

export const languages = [
  { value: 'nl', key: 'Nederlands' },
  { value: 'en', key: 'English' },
];

export function changeLocale(setting) {
  i18n.global.locale = setting;
  EventBus.emit('locale', setting);
}
