<template>
  <router-view :client="client" />
</template>

<script>
import store from '@/store';

export default {
  computed: {
    client() {
      return store.auth.user.client;
    },
  },
};
</script>
