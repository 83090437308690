import axios from 'axios';
import store from '@/store';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

// Adds locale header
apiClient.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = i18n.global.locale; // eslint-disable-line no-param-reassign
  return config;
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response
      && [401, 419].includes(error.response.status)
    ) {
      store.auth.user = null;
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  },
);

export default apiClient;
